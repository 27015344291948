<template>
  <v-container class="container--fluid grid-list-md">
    <h2 class="mb-2">{{$t('route.excels.export')}}</h2>
  </v-container>
</template>

<script>
export default {
name: "exportExc"
}
</script>

<style scoped>

</style>
